import { useStore } from "vuex";
import asyncOperations from "./asyncOperations";
import {
  computed,
  watchEffect,
  watch,
  getCurrentInstance,
  isRef,
  reactive,
  ref,
  nextTick,
  unref,
} from "vue";

import _ from "lodash/lang";

// target is a string - attempt to get from instance
let deepUnref = (sourceObj) => {
  return unref(_.cloneDeep(sourceObj));
};

export default ({
  target,
  endpoint: endpointArg,
  form,
  formData,
  store,
  asyncOps,
  callback: incomingGeneralCallback,
  successCallback: incomingSuccessCallback,
  errorCallback: incomingErrorCallback,
  cancelCallback: incomingCancelCallback,
  showNotifications,
  instance,
  enabled,
}) => {
  // reactive state
  let endpoint = ref("");
  let isEditing = ref(false);
  let isReady = ref(false);
  let formConfig = ref({});
  let currentFields = reactive({});
  let editorComponentFormConfig = reactive({});
  let isLastOperationSuccessful = ref(false);

  // default some stuff from arguments

  let generalCallback =
    typeof incomingGeneralCallback === "function"
      ? incomingGeneralCallback
      : async () => {
          return true;
        };
  let successCallback =
    typeof incomingSuccessCallback === "function"
      ? incomingSuccessCallback
      : async () => {
          return true;
        };
  let errorCallback =
    typeof incomingErrorCallback === "function"
      ? incomingErrorCallback
      : async () => {
          return true;
        };
  let cancelCallback =
    typeof incomingCancelCallback === "function"
      ? incomingCancelCallback
      : async () => {
          return true;
        };

  instance = instance || getCurrentInstance();
  store = store || useStore();
  formData = formData || {};
  showNotifications = typeof showNotifications === "boolean" ? showNotifications : true;
  asyncOps = asyncOps || asyncOperations({}, store);
  let isEnabledInput = typeof enabled === "boolean" ? enabled : true;

  // TODO validate target, endpoint and form

  function populateTarget() {
    // TODO: right now we are just using getTarget() everytime (becuase the instance may change all the time)
    if (!target || typeof target !== "object") {
      // warn("useQuickEdit - target should be object. expect problems.");
    }
  }

  function populateForm() {
    if (form && typeof form === "object") {
      formConfig.value = form;
      isReady.value = true;
    }

    // load form from assets
    if (typeof form === "string") {
      let formPath = `forms/${form}.js`;
      (async () => {
        const formImport = await utilities.requireAsset(formPath);
        if (!formImport) {
          warn("useQuickEdit - form faild to load. expect problems");
          return;
        }

        const formFactory = formImport.default ? formImport.default : formImport;

        let generatedForm;
        if (typeof formFactory === "function") {
          generatedForm = await formFactory(formData);
        } else {
          generatedForm = formFactory;
        }

        if (!generatedForm || typeof generatedForm !== "object") {
          warn(
            "useQuickEdit - attempted to load form by string, but result is not an object"
          );
          return false;
        }

        // todo: try to load edit form from server by this string, before declaring failure
        formConfig.value = generatedForm;
        isReady.value = true;
      })();
    }
  }

  function populateEndpoint() {
    if (isRef(endpointArg)) {
      // this is intentional
      // eslint-disable-next-line vue/no-ref-as-operand
      endpoint = endpointArg;
      return true;
    }
    if (typeof endpointArg === "string") {
      endpoint.value = endpointArg;
      return true;
    }

    warn("useQuickEdit - endpoint argument property must be a string. expect problems.");
    return false;
  }

  populateTarget();
  populateForm();
  populateEndpoint();

  const setEndpoint = (arg) => {
    endpointArg = arg;
    populateEndpoint();
  };
  const setTarget = (arg) => {
    target = arg;
  };

  const targetData = reactive({});
  const finalTarget = computed(() => {
    // target given
    if (target && typeof target === "object") {
      return target;
    }

    // target is a string
    let targetData = instance.ctx[target];
    return targetData;
    //return instance.ctx[target];
  });

  const getOriginalTarget = () => {
    // target given
    if (target && typeof target === "object") {
      return target;
    }


    if (instance.ctx[target]) {
      return instance.ctx[target];
    }

    if (instance?.data[target]) {
      return instance?.data[target];
    }

    if (instance?.proxy[target]) {
      return instance?.proxy[target];
    }

    return instance.ctx[target];
  }
  const getTarget = () => {
    let result = getOriginalTarget();
    return deepUnref(result);
  };
  // set our form by argument

  // TODO set form with fetching from server
  let isEnabled = ref(isEnabledInput);

  const showNotification = async (text, type, options) => {
    if (!showNotifications) {
      return false;
    }
    if (instance?.ctx?.$s?.ui?.notification) {
      instance.ctx.$s.ui.notification.closeAll();
      await utilities.nextTick();
      return instance.ctx.$s.ui.notification(text, type, options);
    }

    return true;
  };

  const finalGeneralCallback = (arg) => {
    const { component, data, isError, result } = arg;
    isEditing.value = false;
    generalCallback(arg);
  };

  const finalSuccessCallback = async (arg) => {
    let cbRes = await successCallback(arg, { isLastOperationSuccessful, isEditing });
    if (!cbRes) {
      return true;
    }
    // update the subject
    let targetValue = getOriginalTarget(); //finalTarget.value;

    if (isRef(targetValue)) {
      targetValue = targetValue.value;
    }

    Object.keys(currentFields).map((key) => {
      targetValue[key] = arg.data[key];
    });

    isLastOperationSuccessful.value = true;
    isEditing.value = false;
    showNotification("core.quickEditor.generalSuccessNotification", "success");
    return true;
  };

  const finalErrorCallback = async (arg) => {
    let cbRes = await errorCallback(arg, { isLastOperationSuccessful, isEditing });
    if (!cbRes) {
      return false;
    }

    isLastOperationSuccessful.value = false;
    isEditing.value = false;
    showNotification("core.quickEditor.generalErrorNotification", "error");
    return false;
  };

  const finalCancelCallback = async (arg) => {
    let cbRes = await cancelCallback(arg, { isLastOperationSuccessful, isEditing });
    if (!cbRes) {
      return false;
    }

    showNotification("core.quickEditor.cancelNotification");
    isLastOperationSuccessful.value = null;
    isEditing.value = false;
    return null;
  };

  const getAllFields = () => {
    let all = formConfig.value.fields;
    if (!all) {
      return {};
    }

    return all;
  };

  const getAllFieldNames = () => {
    return Object.values(getAllFields()).map((field) => field.name);
  };

  let editorComponentConfig;
  const resetComponentData = () => () => {
    //console.log('reset data', editorComponentConfig);
  };

  editorComponentConfig = reactive({
    show: isEditing,
    enabled: isEnabled,
    targetData: targetData,
    getTargetData: getTarget,
    generalCallback: finalGeneralCallback,
    errorCallback: finalErrorCallback,
    successCallback: finalSuccessCallback,
    cancelCallback: finalCancelCallback,
    resetData: () => {
      try {
        resetComponentData();
      } catch (e) {}
    },
    formProps: {
      method: "patch",
      action: endpoint,
      // TODO: callback for us, to act upon
      config: editorComponentFormConfig,
    },
  });

  watchEffect(() => {
    // editorComponentConfig.formProps.config = formConfig;
  });

  watch(
    currentFields,
    (newVal) => {
      editorComponentFormConfig.fields = { ...currentFields };
      editorComponentFormConfig.buttons = {};
    },
    { deep: true }
  );

  const submitEdit = async (fields) => {
    console.log("TODO: submitEdit");
  };

  const setCurrentFields = (fields) => {
    let targetFields = fields;

    if (typeof fields === "string") {
      targetFields = { [fields]: {} };
    }

    if (Array.isArray(fields)) {
      targetFields = {};
      fields.forEach((key) => (targetFields[key] = {}));
    }

    Object.keys(currentFields).map((key) => {
      if (!Object.keys(targetFields).includes(key)) {
        delete currentFields[key];
      }
    });

    if (!targetFields || typeof targetFields !== "object") {
      warn(
        "useQuickEdit: startEdit: can not turn the targetFields argument into an object. Accepting string, array of strings, or object."
      );
    }
    Object.entries(targetFields).map(([key, conf]) => {
      if (!Object.entries(currentFields).includes(key)) {
        currentFields[key] = conf;
      }
    });
    // overload the fields with original configuration
    Object.keys(currentFields).map((key) => {
      let base = formConfig.value.fields[key];
      currentFields[key] = { ...base, ...currentFields[key] };
    });
    // we have a watcher for this effect
    return true;
  };

  const startEdit = async (fields, options) => {
    if (fields === "all") {
      fields = getAllFieldNames();
    }
    setCurrentFields(fields);
    isEditing.value = true;

    return new Promise((fulfil, reject) => {
      nextTick(() => {
        watchEffect(() => {
          if (isEditing.value === true) {
            fulfil(isLastOperationSuccessful.value);
          }
        });
      });
    });
  };

  const enable = () => {
    isEnabled.value = true;
  };

  const disable = () => {
    isEnabled.value = false;
  };

  return {
    // the entire export as an object
    editor: {
      directive: {},
      isEnabled,
      enable,
      disable,
      startEdit,
      submitEdit,
      editorComponentConfig,
      editorFormConfig: editorComponentFormConfig,
      formConfig,
      getTarget,
      setTarget,
      setEndpoint,
    },
    directive: {},
    isEnabled,
    enable,
    disable,
    startEdit,
    submitEdit,
    editorComponentConfig,
    editorFormConfig: editorComponentFormConfig,
    formConfig,
    setTarget,
    setEndpoint,
  };
};
